import React from 'react';
import { useRouter } from 'next/router';

// Utils
import { brand } from '@/brand/brand';
// Components
import { Page } from '@/components/common';
import InfoPage from '../../components/Pages/InfoPage/InfoPage';
import pageNotFound from '@/assets/images/page-not-found.png';

export default function PageNotFound() {
  const router = useRouter();

  const handleClick = () => {
    router.push(brand.loggedInHomeRoute);
  };

  return (
    <Page>
      <InfoPage
        headerText="404 page not found."
        subText1="Ah, the dreaded 404. Let's get you back on track."
        buttonText="Try again"
        buttonCallback={handleClick}
        imageSource={pageNotFound.src}
        imageAltText="page not found"
      />
    </Page>
  );
}
